import React from "react";
import { useParams } from "react-router-dom";
import Data from "./EmployeeApi";
import "./Employee.css";
import Confetti from "react-confetti";

const Employee = () => {
  const { uniqueId } = useParams();
  const employee = Data.find((employee) => employee["Unique-Id"] === uniqueId);

  if (!employee) {
    return <div>Employee not found</div>;
  }

  return (
    <div className="Employee container my-3">
      <h2>StartupKt Intern Details:</h2>
      <div className="row m-3 p-3">
        <div className="row m-3 p-3">
          <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center">
            <img
              className="image--cover"
              src={employee.img}
              alt="Candidate img"
            />
            <h2>{employee.name}</h2>
            <p>{employee.Position}</p>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <p>Company: {employee.company}</p>
          <p>Employee Id: {employee["Unique-Id"]}</p>
          <p>Nationality: {employee.Nationality}</p>
          <p>Designation: {employee.Designation}</p>
          <p>Duration: {employee.duration}</p>
          <p>Remarks: {employee.description}</p>
          <p>Status: {employee.Status}</p>
        </div>
      </div>
    </div>
  );
};

export default Employee;
