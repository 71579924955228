import React from 'react'
import About1 from './About1';
import About2 from './About2';

import "./About.css"
import About3 from './About3';


const About = () => {
  return (
    <>
      <About1/>
    <div id='About'>
      <About2/>
      <About3/>
    </div>
    </>
  )
}

export default About
