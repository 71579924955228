const Data =  [
  {
    "id":1,
    "name":"Chirag Sharma",
    "Position":"Front-End Developer Intern ",
    "company":"Startup-Kt(Main)",
    "duration":"15th January 2023 - 15th March 2023",
    "description":"During the Internship, Chirag showed he is quick at learning new technologies, sincere and creative. We wish him the best for future endeavours",
    "img":"https://i.postimg.cc/br9VGNF7/Whats-App-Image-2023-06-13-at-23-05-11.jpg",
    "Unique-Id":"48a3b0d0-3fad-4060-b87c-dfccb3094096",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":2,
    "name":"Hari Karumuri",
    "Position":"Full-Stack Developer",
    "company":"Startup-Kt(Main)",
    "duration":"12th September 2022 - Working",
    "description":"-",
    "img":"https://i.postimg.cc/RhfZ1hm4/Whats-App-Image-2023-06-14-at-01-16-23.jpg",
    "Unique-Id":"cb8b9bd5-cc8d-4545-b89d-85f2c553ddda",
    "Nationality": "Indian",
    "Designation": "Full-Time Employee",
    "Status": "Working",
  },
  {
    "id":3,
    "name":"Navin Patidar",
    "Position":"Front-End Developer Intern ",
    "company":"Startup-Kt",
    "duration":"1st July 2023 - 31st August 2023",
    "description":"Throughout the Internship, Navin consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/qqH17H9D/Whats-App-Image-2023-06-14-at-23-11-56.jpg",
    "Unique-Id":"4da5a5a6-c4d0-4bbf-a2f7-481a78406d99",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":4,
    "name":"Aditya Ajit Upadhye",
    "Position":"Front-End Developer Intern ",
    "company":"Startup-Kt",
    "duration":"12th January,2023 - 12th March,2023",
    "description":"Throughout the Internship, Aditya consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/kMj7fz0V/IMG-9745.jpg",
    "Unique-Id":"7afbc764-20c0-4a6a-907a-ff1b4b7d781c",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":5,
    "name":"Suvadip Hazra",
    "Position":"Java Developer",
    "company":"Startup-Kt",
    "duration":"15th January,2023 - 15th March,2023",
    "description":"Throughout the Internship, Suvadip consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/3RNXKJ9H/Suvadip-img.png",
    "Unique-Id":"37be8e44-0d98-43e3-bfc3-be2d42e462c4",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":6,
    "name":"Allam Sumith Reddy",
    "Position":"Front-End Developer Intern",
    "company":"Startup-Kt",
    "duration":"10th January,2023 - 10th March,2023",
    "description":"Throughout the Internship, Sumith consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/rmGkwMpQ/SUmith-img.jpg",
    "Unique-Id":"cfd54ef1-44d1-4bdf-ac31-ce379af303ac",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":7,
    "name":"Ankita Mondal",
    "Position":"Java Developer",
    "company":"Startup-Kt",
    "duration":"11th January,2023 - 11th March,2023",
    "description":"Throughout the Internship, Ankita consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish her the best for future endeavors.",
    "img":"https://i.postimg.cc/qRK7cPv3/ankita-img.png",
    "Unique-Id":"a4a4f843-2d20-48c1-b295-c08801740171",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":8,
    "name":"Anurag Chittoria",
    "Position":"Cyber Security Intern",
    "company":"Startup-Kt",
    "duration":"11th January,2023 - 11th March,2023",
    "description":"Throughout the Internship, Anurag consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/KvftgMVy/ANurag-img.jpg",
    "Unique-Id":"49596869-5266-41fd-b35c-872f67e8292c",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":9,
    "name":"KMD Khaja",
    "Position":"Front-End Development Intern",
    "company":"Startup-Kt",
    "duration":"1st July,2023 - 31th July ,2023",
    "description":"Throughout the Internship, Khaja consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/QdWxrLcn/Khaja-img.jpg",
    "Unique-Id":"1e43ad08-e2c0-4abf-9990-5c2ac0ea7c79",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":10,
    "name":"Ichitaka Yamamoto",
    "Position":"Machine Learning Intern",
    "company":"Startup-Kt",
    "duration":" 15th Jan,2023 - 15th March,2023",
    "description":"Throughout the Internship, Ichi consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/90NCxgfH/ichi-img.jpg",
    "Unique-Id":"cbbcc1b6-a9bb-4ae5-b3f1-37ce8fb5f90f",
    "Nationality": "Japanese",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":11,
    "name":"Vasistha Arvind Rathore",
    "Position":"Python Developer Intern",
    "company":"Startup-Kt",
    "duration":" 15th Jan,2023 - 15th March,2023",
    "description":"Throughout the Internship, Arvind consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/HxhCgtMt/arvindimg.jpg",
    "Unique-Id":"bd8e0fa5-5f3f-42a7-95a4-5cbfc2a07e67",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":12,
    "name":"Sachin Rao Jupally",
    "Position":"Front-End Developer Intern",
    "company":"Startup-Kt",
    "duration":" 10th Jan,2023 - 10th March,2023",
    "description":"Throughout the Internship, Sachin consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/QxVTYkqN/sachin-img.jpg",
    "Unique-Id":"f4546ecd-b385-4e50-b922-7a453c447fe6",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":13,
    "name":"Chalukya Chowdary",
    "Position":"Front-End Developer Intern",
    "company":"Startup-Kt",
    "duration":" 15th Jan,2023 - 15th March,2023",
    "description":"Throughout the Internship, Chalukya consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish her the best for future endeavors.",
    "img":"https://i.postimg.cc/k4175m0K/chalukya-img.jpg",
    "Unique-Id":"21021bf7-0047-4b8b-be28-280efb882a76",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":14,
    "name":"Hitanshi Verma",
    "Position":" Assistant Business Executive",
    "company":"Startup-Kt",
    "duration":" 15th Feb,2023 to 15th April,2023",
    "description":"Throughout the Internship, Hitanshi Verma consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish her the best for future endeavors.",
    "img":"https://i.postimg.cc/D0dbP1Tr/Hitanshi.jpg",
    "Unique-Id":"bf999b9f-fa99-48e6-a151-a54d788402eb",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":15,
    "name":"Markapuram Rama Prasad",
    "Position":"Front-End Developer Intern",
    "company":"Startup-Kt",
    "duration":"15th Jan,2023 to 15th March,2023",
    "description":"Throughout the Internship, Rama Prasad consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/dQ6qRHzW/ramprasad-img.png",
    "Unique-Id":"b6d3b0c6-7310-4812-bb54-e95fd7008ffe",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":16,
    "name":"EDIGA VENKATESH GOWD",
    "Position":"Front-End Development Intern ",
    "company":"Startup-Kt",
    "duration":"15th Jan,2023 to 15th March,2023",
    "description":"Throughout the Internship, Venkatesh consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/hPnKkHLP/venkatesh-img.jpg",
    "Unique-Id":"d9534d2c-c4f2-4723-8081-8a7964bcd735",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":17,
    "name":"Gutlapalli Gnana Charitha",
    "Position":"Python Developer Intern ",
    "company":"Startup-Kt",
    "duration":"12th January,2023 to 12th March,2023",
    "description":"Throughout the Internship, Charitha consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish her the best for future endeavors.",
    "img":"https://i.postimg.cc/SRTbChSR/charitha-photo.png",
    "Unique-Id":"6b9e79f7-b7b7-4c9d-a9e7-d3cff615ca86",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":18,
    "name":"Anke Umashankar",
    "Position":"Front end Development Intern",
    "company":"Startup-Kt",
    "duration":"15th January,2023 to 15th March,2023",
    "description":"Throughout the Internship, Umashankar consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/c4SnbGB2/Whats-App-Image-2023-06-22-at-15-19-38.jpg",
    "Unique-Id":"3be44038-7c0a-47b0-96e1-1e036653cca6",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":19,
    "name":"Sparsh Sangar",
    "Position":" Front-End Development Intern",
    "company":"Startup-Kt",
    "duration":"15th Jan,2023 to 15th March,2023",
    "description":"Throughout the Internship,  consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/wjK69DBG/sparsh-img.jpg",
    "Unique-Id":"64aa024b-f039-4c40-b76b-eeb39e941785",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":20,
    "name":"Ritesh Tiwari",
    "Position":"Finance Department Intern ",
    "company":"Startup-Kt",
    "duration":"5th June,2023 to 5th August,2023",
    "description":"Throughout the Internship,Ritesh  consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/KY2B3K09/Whats-App-Image-2023-06-24-at-23-11-19.jpg",
    "Unique-Id":"170caff5-9eeb-47cc-b2d7-38ac2b0910cc",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":21,
    "name":"Juhi Singh",
    "Position":"Data Analyst Intern",
    "company":"Startup-Kt",
    "duration":"12th Jan,2023 to 12th March,2023",
    "description":"Throughout the Internship,Juhi consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish her the best for future endeavors.",
    "img":"https://i.postimg.cc/L8VP9Rn9/juhi.jpg",
    "Unique-Id":"64751544-86d2-4b7e-8a74-a98df681305e",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":22,
    "name":"KURRA ABHISHEK",
    "Position":"Frontend Developer Intern",
    "company":"Startup-Kt",
    "duration":"12th Jan,2023 to 12th March,2023",
    "description":"Throughout the Internship, Abhishek consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/L6nCfq8v/abhi.jpg",
    "Unique-Id":"66c612cb-4759-4ba8-ae06-8b3b5cab662d",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":23,
    "name":"Navya Narakatla",
    "Position":"Data Analyst Intern",
    "company":"Startup-Kt",
    "duration":"12th Jan,2023 to 12th March,2023",
    "description":"Throughout the Internship,Juhi consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish her the best for future endeavors.",
    "img":"https://i.postimg.cc/DzMd76qT/Navya.jpg",
    "Unique-Id":"51b7210a-83e9-4513-beb4-3853d344651f",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":24,
    "name":"Tushar Sharma",
    "Position":"Java Developer Intern",
    "company":"Startup-Kt",
    "duration":"12th Jan,2024 to 12th March,2024",
    "description":"Throughout the Internship,Tushar consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/B62gNDKf/tushar.jpg",
    "Unique-Id":"2928d47a-fdc2-4e0f-b983-25c2c541e2b8",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":25,
    "name":"Prasanth Gautham",
    "Position":"Full Stack developer Intern",
    "company":"Startup-Kt",
    "duration":"10th July,2023 to 10th August,2023.",
    "description":"Throughout the Internship,Prasanth consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/256SV1Y7/IMG-20230722-WA0014.jpg",
    "Unique-Id":"d15e0318-d966-470b-a717-be7ee241ef9b",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },
  {
    "id":26,
    "name":"OM ANAMITRA DASH",
    "Position":"Front end developer Intern",
    "company":"Startup-Kt",
    "duration":"10th July,2023 to 10th August,2023",
    "description":"Throughout the Internship,he consistently demonstrated a proactive approach to learning new technologies, showcasing dedication and an ability to think outside the box. We wish him the best for future endeavors.",
    "img":"https://i.postimg.cc/PxCJFPF5/IMG-20230722-WA0013.jpg",
    "Unique-Id":"188269e8-eb95-496a-a60d-d8c85d42cff4",
    "Nationality": "Indian",
    "Designation": "Internship",
    "Status": "Completed",
  },

  
]

export default Data;