import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./SinglePage.css";
import img2 from "../../../Assests/mobile.png";
import { Helmet } from "react-helmet";

const UiPage = () => {
  const scrollUp = () => {
    window.scroll(0, 0);
  };

  useEffect(() => {
    scrollUp();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Best UI/UX Design & Development Services in Bangalore | StartupKT{" "}
        </title>
        <meta
          name="description"
          content="Get UI/UX Best design & development services in bangalore for you business growth, get more customers attraction & better conversions"
        />
        <meta name="keywords" content="ui/ux for services in Bangalore" />
      </Helmet>
      <div className="container UI">
        <nav className="m-3" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/Services">Services</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              UI/UX DESIGN
            </li>
          </ol>
        </nav>
        <div className="row d-flex justify-content-center align-items-center">
          <blockquote class="blockquote">
            <svg
              viewBox="0 0 512 512"
              width="40"
              title="quote-left"
              class="quote-icon"
            >
              <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z" />
            </svg>
            <h1 class="quote">
              Design is the beauty of turning constraints into Advantage
            </h1>

            <hr />
          </blockquote>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <h4>Welcome to StartupKT's UI/UX Services!</h4>
            <ul>
              <li>
                At StartupKT, we believe that great user experiences are key to
                success. We specialize in UI/UX design and development, helping
                our clients create engaging and intuitive interfaces that enable
                their customers to interact with their products and services.
                Our team of experienced designers and developers are committed
                to delivering the highest quality user experiences that meet and
                exceed customer expectations.
              </li>
              {/* <li>
                User Interface and User Experience matters a lot before getting
                into any project development. It gives you a visual experience
                by it looks and Navigation.
              </li>
              <li>
                Designing helps you/client to see whether your Idea has been
                come to reality.
              </li>
              <li>
                For a start-up or a small enterprise, the significance of UI and
                UX Design becomes truly more vital as the first impression lasts
                long and using UI and UX designing can make or break the brand
                recognition.
              </li> */}
            </ul>

            <h3>UI/UX services include : </h3>
            <ul>
              {/* <li>
                The stylish designs instill confidence in your brand and inspire
                the users to engage with your products. We garnish all our
                applications through interactive and engaging UI/ UX interfaces.
                Our brilliant developers understand what people like and create
                uniquely beautiful and functional work.
              </li> */}
              <li>
                <span className="fw-semibold"> User research : </span>
                Gathering user feedback, analyzing user behavior, and conducting
                user interviews.
              </li>
              <li>
                <span className="fw-semibold">
                  Design strategy and concept development :
                </span>
                 Creating user personas, developing user journey maps, and
                storyboarding.
              </li>
              <li>
                <span className="fw-semibold">Interaction design : </span>
                Creating wireframes and prototypes, designing user interfaces,
                and developing navigation systems.
              </li>
              <li>
                <span className="fw-semibold">Usability testing : </span>
                Conducting user tests, analyzing user feedback, and refining
                designs.
              </li>
              <li>
                <span className="fw-semibold"> Visual design : </span>
                Creating visual design guidelines, designing icons and graphics,
                and developing responsive layouts.
              </li>
              <li>
                <span className="fw-semibold"> UX/UI Design : </span>
                Our team of experienced designers will work closely with you to
                create a user experience that is tailored to your product or
                service. We will help you craft an engaging and intuitive
                interface that your customers will love.
              </li>
              <li>
                <span className="fw-semibold">UX/UI Development : </span>
                Our experienced developers will help you develop a UI/UX that is
                tailored to your business needs. We will help you create a
                powerful and robust user interface that will enable your
                customers to interact with your product or service in the most
                efficient way possible.
              </li>
              <li>
                <span className="fw-semibold"> User Testing : </span>
                Our team is experienced in user testing and analysis. We will
                help you identify areas for improvement and develop strategies
                to ensure that your user experience meets and exceeds customer
                expectations.
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 d-flex  align-items-center">
            <img className="img-fluid" src={img2} alt="Phone illustrator" />
          </div>
        </div>
      </div>
    </>
  );
};

export default UiPage;
