import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import "./SinglePage.css";
import img1 from "../../../Assests/digitalmarket.png";
import img2 from "../../../Assests/digitalmarket2.png";
import { Helmet } from "react-helmet";

const DigiMarkPage = () => {
  const scrollUp = () => {
    window.scroll(0, 0);
  };

  useEffect(() => {
    scrollUp();
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          Best Digital Marketing | SEO | SEM | SMM services in bangalore |
          StartupKT
        </title>
        <meta
          name="description"
          content="Digital Marketing services for Business | Website Ranking, Social Media Marketing, Paid Advertise Marketing, E - commerce, Brand Building at best competitive prices"
        />
        <meta name="keywords" content="digital marketing freelancer " />
        <meta
          name="keywords"
          content="best digital marketing service in bangalore "
        />
      </Helmet>
      <div className="container Digi">
        <nav aria-label="breadcrumb" className="m-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/Services">Services</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Digital Marketing
            </li>
          </ol>
        </nav>
        <h1>
          “StartupKT Business Solutions” the best among the Digital Marketing
          Companies in Bangalore.
        </h1>
        <div className="row mt-4 g-3">
          <div className="col-lg-7 col-md-6 ">
            <h5>Transform your digital presence from scratch with us.</h5>
            <p>
              StartupKT specializes in digital marketing services. Our services
              enable companies to reach their target audiences through effective
              and innovative marketing strategies. We have a team of experienced
              and knowledgeable professionals who are passionate about
              delivering results and creating value for our clients.
            </p>
            <p>
              We understand the importance of digital marketing for businesses,
              and our services are designed to help companies make the most of
              it. We provide comprehensive digital marketing solutions, from
              planning to implementation and follow-up. We offer a wide range of
              services, from SEO and PPC campaigns to website design and content
              creation. We can help you create and manage successful campaigns
              that drive traffic and increase sales.
            </p>
            <p>
              StartupKT also offers social media marketing services, which can
              help you build an engaged audience and increase brand awareness.
              We specialize in creating campaigns that resonate with your target
              audience and grow your business. We help you create content that
              engages and educates your followers, and we track the performance
              of your campaigns to ensure maximum return on investment.
            </p>
          </div>
          <div className="col-lg-5 col-md-6">
            <img className="img-fluid display_none" src={img1} alt="Sale illustrator" />
          </div>
          <div className="col-lg-5 col-md-6">
            <img className="img-fluid" src={img2} alt="Sale illustrator" />
          </div>
          <div className="col-lg-7 col-md-6 ">
            <p>
              Our team of experts also provides analytics and reporting services
              to help you track your performance and measure the success of your
              campaigns. We provide detailed reports and insights to help you
              understand your campaign's performance and make the necessary
              adjustments.
            </p>
            <p>
              StartupKT is committed to delivering results and creating value
              for our clients. We understand that digital marketing is an
              ever-changing landscape, and we are always up-to-date with the
              latest trends and technologies. We strive to provide the best
              service possible, and we guarantee satisfaction.
            </p>
            <p>
              If you are looking for a reliable and experienced digital
              marketing partner, then StartupKT is the perfect choice. Contact
              us today to learn more about our services and how we can help your
              business succeed.
            </p>
          </div>
          <div className="col-lg-12 col-md-12 ">
            <ul>
              <h5>StartupKT Digital Marketing Services:-</h5>
              <li>
                Provide comprehensive digital marketing strategies to help
                startups reach their goals
              </li>
              <li>Create and maintain a comprehensive online presence</li>
              <li>
                Develop and execute campaigns that are tailored to the needs of
                the startup
              </li>
              <li>Create and manage content across various online platforms</li>
              <li>
                Implement SEO and SEM strategies that will bring in more
                targeted traffic
              </li>
              <li>Design and implement email marketing campaigns</li>
              <li>
                Analyze data and track results to ensure campaigns are meeting
                objectives
              </li>
              <li>
                Provide ongoing monitoring and reporting of results and trends
              </li>
              <li>
                Manage budget and strive to obtain maximum ROI for the startup
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigiMarkPage;
